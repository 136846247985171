import $axios from './api';

export const list = async (payload = {}) => {
	const { data } = await $axios.post('/v1/admin/refund/list', payload);
	return data;
};

export const getById = async (id) => {
	const { data } = await $axios.get(`/v1/admin/refund/${id}`);
	return data;
};

export const markAsPaid = async (id, status ) => {
	const { data } = await $axios.post(`/v1/admin/refund/${id}`, { status });
	return data;
};
